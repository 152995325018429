<template>
  <div>
    <p v-if="isAdd" @click="showAdd" :style="{cursor: 'pointer'}">-</p>
    <div v-if="!isAdd" :style="{display: 'flex', alignItems: 'center'}">
      <ui-input ref="field" v-model="number.amount" @input="checkInputValue"/>
      <img
          :style="{width: '16px', height: '16px', cursor: 'pointer'}"
          src="@/assets/images/check-active.png"
          alt=""
          @click="addNumber"
          v-if="number.amount"
      >
    </div>
  </div>
</template>

<script>
import UiInput from "@/components/ui/UiInput.vue";
import {mapActions} from "vuex";

export default {
  name: "TableColumnNumber",
  components: {UiInput},
  props: {
    column: {
      type: Object,
      default: () => {}
    },
    row: {
      type: Object,
      default: () => {}
    },
    data: {
      type: [Object, Array],
      default: () => {}
    }
  },

  data () {
    return {
      isAdd: true,
      number: {
        amount: '',
        orderSubtypeId: '',
        specColumnId: 0,
        specValueId: null,
      }
    }
  },

  methods: {
    ...mapActions(['addColumnNumber']),
    showAdd () {
      this.isAdd = !this.isAdd
      this.number.amount = ''
    },
    addNumber () {
      let data = {
        columnId: this.column.id,
        rowId: this.row.id,
        amount: this.number.amount.replaceAll(',', '.'),
        specValueId: this.data?.id
      }

      this.$emit('addNewNumber', data)
    },
    checkInputValue() {
      isNaN(+this.number.amount) ? this.number.amount = '' : true
    }
  },
}
</script>

<style lang="scss" scoped>

</style>