<template>
  <div>
    <p v-if="editColumnNum" @click="showEditColumnNumber" :style="{cursor: 'pointer'}">{{ item.amount === 0 ? 0 : item.amount || '-' }}</p>
    <div v-else :style="{display: 'flex', alignItems: 'center'}">
      <ui-input v-model="numberValue" ref="field" @input="checkInputValue"/>
      <img
          :style="{width: '16px', height: '16px', cursor: 'pointer'}"
          src="@/assets/images/check-active.png"
          alt=""
          @click="editNumber"
          v-if="numberValue"
      >
    </div>
</div>
</template>

<script>

import UiInput from "@/components/ui/UiInput.vue";

export default {
  name: "TableColumnNumberEdit",
  components: {UiInput},

  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      editColumnNum: true,
      numberValue: ''
    }
  },

  methods: {
    showEditColumnNumber() {
      this.editColumnNum = !this.editColumnNum
      this.numberValue = this.item.amount.toString()
    },
    editNumber() {
      this.item.numVal = this.numberValue.replaceAll(',', '.')
      this.$emit('editColumnNumber')
    },
    checkInputValue() {
      isNaN(+this.numberValue) ? this.numberValue = '' : true
    }
  },

  mounted () {
    this.numberValue = this.item.amount.toString()
  }
}
</script>

<style lang="scss" scoped>

</style>